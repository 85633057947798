.Content{
    margin-top: 115px;
    margin-left: 15%;
    /*background:linear-gradient(221deg, rgb(237, 243, 247) 0%, rgb(224, 238, 252) 100%);
*/}

.Christmas {

        position: fixed;
        right: 0;
        z-index: 1030;
        box-sizing: border-box;
        border: 1rem #063963;
        vertical-align: middle;
        cursor: pointer;
        pointer-events:visibleFill;
}

@media (max-width: 499px) {
    .Content{
        margin-top: 72px;
        margin-left: 0%;
    }
  }