.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
  }
  .NavigationItem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
  
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a:focus,
  .NavigationItem a.active {
      background-color:#0B79D0;
      border-bottom: 4px solid #40A4C8;
      color: white;
  }

  .ActiveItem{
      background-color:#0B79D0;
      border-bottom: 4px solid #40A4C8;
      color: white;
  }
  
  
  @media (min-width: 500px) {
    .NavigationItem {
      margin: 0 0 !important;
      display: flex;
      height: 100%;
      width: auto;
      align-items: center;
    }
    .NavigationItem a {
      color: white;
      height: 100%;
      padding: 16px 10px;
      border-bottom: 4px solid transparent;
    }
  
    .NavigationItem a:hover,
    .NavigationItem a:active
    .NavigationItem a.active {
      background-color: #0B79D0;
      border-bottom: 4px solid #40A4C8;
      color: white;
    }
  }
  